.btn-yellow-gm {
    --bs-btn-font-weight: 600;
    --bs-btn-color: white;
    --bs-btn-bg: var(--primary-yellow);
    --bs-btn-border-color: none;
    --bs-btn-border-radius: 10px;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg:  #edbb58;
    --bs-btn-focus-shadow-rgb: black;
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #{shade-color(var(--primary-yellow), 20%)};
}

// button + a element disabled css classes
.btn:disabled{
    color: var(--primary-yellow);
    background-color: var(--base-secondary-color);
    border: white solid 1px;
}
.btn.disabled{
    color: var(--primary-yellow);
    background-color: var(--base-secondary-color);
    border: white solid 1px;
}

.btn-orange-gm {
    --bs-btn-font-weight: 600;
    --bs-btn-color: white;
    --bs-btn-bg: var(--primary-orange);
    --bs-btn-border-color: none;
    --bs-btn-border-radius: 10px;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg:  #e66950;
    --bs-btn-focus-shadow-rgb: black;
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #{shade-color(var(--primary-orange), 20%)};
}

.btn-pink-gm {
    --bs-btn-font-weight: 600;
    --bs-btn-color: white;
    --bs-btn-bg: var(--primary-pink);
    --bs-btn-border-color: none;
    --bs-btn-border-radius: 10px;
    --bs-btn-hover-color: white;
    --bs-btn-hover-bg:  #e8437b;
    --bs-btn-focus-shadow-rgb: #e63571;
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #{shade-color(var(--primary-pink), 20%)};
}

