.key-features-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    width: 100%;
}

.main-text {
    text-align: center;
    color: white;
}

.feature {
    background-color: var(--base-secondary-color);
    border-radius: 10px;
    width: 300px;
    height: 215px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 767px){
    .feature-sm {
        margin-bottom: 20px;
    }
}
@media screen and (min-width: 768px){
    .feature-md-above {
        margin-right: 20px;
        margin-bottom: 20px;
    }
}