:root{
  --primary-orange: #EB4828;
  --primary-pink:#EB286A;
  --primary-yellow: #EBAA28;
  --base-color: #13141C;
  --base-secondary-color: #1F2028;
  --completed-color: #386641;
}

// .box {
//   margin: 0;
//   padding: 0;
// }

html {
  width: 100%;
  height: 100%;
}

body {
  height: 100vh;
  width: 100%;
  background-color:#13141C;
  color: #FFFFFF;
  margin: 0;
  font-family: 'Lato', sans-serif, -apple-system, BlinkMacSystemFont,'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: bolder;
  letter-spacing: 0.04em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
