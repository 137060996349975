.Hero {
    width: 100%;
    height: fit-content;
    display: inline-block;
    // TODO: remove later
    margin-bottom: 45px;
    margin-top: 75px;
}

.Hero-main-text {
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.04em;
}

.Hero-subtext {
    text-align: center;
    margin-left:25px;
    margin-right: 25px;
}