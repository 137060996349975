.App-header {
    background-color: #13141C;
    height: 75px;
    max-height: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    color: white;
    border-bottom: 1px solid white;
}

.Header-logo {
    width:50%;
    max-width: 48px;
}

.Header-font {
    font-weight: bolder !important;
}

.Header-hover {
    cursor: pointer;
}