.WorkoutTile {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    border-radius: 14px;
    height: 340px;
    width: 325px;
    background-color: var(--base-secondary-color);
    flex-shrink: 1;
}

.TileWOEdit {
    padding-top: 30px;
}

.TileWEdit {
    padding-top:13px;
}

@media screen and (min-width: 768px){
    .WorkoutTileMargin {
        margin-right: 15px;
    }
}

.Items {
    max-height: 150px;
    height: auto;
    max-width: 300px;
    overflow-y: scroll;
    margin-bottom: 5px;
}

/* always show scrollbars */

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
  
::-webkit-scrollbar-thumb {
border-radius: 4px;
background-color: rgba(0, 0, 0, .5);
box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}