.TransparentInput{
    background-color: transparent;
    border: 1px solid #EB4828
}

.white {
    color: white;
}

a:active, a:hover{
    color: var(--primary-orange)
}