.Item {
    border-radius: 44px;
    height: 95px;
    max-height: 95px;
    min-height: 85px;
    width: 75vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.complete {
    background-color: var(--completed-color);
}
.incomplete {
    background-color: var(--base-secondary-color);
}
.txtHeight {
    max-height: 60px;
    height: auto;
    overflow-y: scroll;
}