.NotesIcon {
    border-radius: 10px;
    border: 1px solid var(--primary-pink);
    padding: 5px;
    font-size: 30px;
}

.linebreak {
    white-space: pre-line;
}

.ToastTextColor {
    color: black
}