.orange {
    color: var(--primary-orange);
    font-weight: bolder;
}
.pink {
    color: var(--primary-pink);
    font-weight: bolder;
}
.yellow {
    color: var(--primary-yellow);
    font-weight: bolder;
}

.Tile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 44px;
    border-radius: 14px;
    height: auto;
    background-color: var(--base-secondary-color);
    flex-shrink: 1;
}

@media screen and (max-width: 767px){
    .TileMobile {
        margin-bottom: 20px;
        max-width: 310px;
        width: 310px;
        min-height: 315px;
    }
}
@media screen and (min-width: 768px){
    .TileAboveMobile {
        margin-right: 20px;
        min-height: 325px;
        max-width: 315px;
        width: 315px;
    }
}