@media screen and (max-width: 767px){
    .TileContainer {
        justify-content: center;
        align-items: center;
    }
}

@media screen and (min-width: 768px) {
    .TileContainer {
        justify-content: space-around;
    }
}