.GoalContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 50px 70px;
    background-color: var(--base-secondary-color);
    border: 1px solid var(--primary-yellow);
    border-radius: 10px;
    align-items: center;
}

.CardioTitle {
    grid-column: 2;
    grid-row: 1;
}

.CardioTime {
    grid-column: 2;
    grid-row: 2;
}

.SetsCol {
    grid-column: 2;
    grid-row: 2;
}
.SetsTitle {
    grid-column: 2;
    grid-row: 1;
    padding-top: 10px;
}

.RepsCol {
    grid-column: 1;
}
.RepsTitle {
    grid-column: 1;
    grid-row: 1;
    padding-top: 10px;
}

.WeightCol {
    grid-column: 3;
}
.WeightTitle {
    grid-column: 3;
    grid-row: 1;
    padding-top: 10px;
}